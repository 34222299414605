import { Component, OnInit } from '@angular/core';
import { LoginService } from '../../../../app/pages/authentication/login/login.service';
import { AuthService } from '../../../shared/services/auth.service';

@Component({
  selector: 'fury-toolbar-user',
  templateUrl: './toolbar-user.component.html',
  styleUrls: ['./toolbar-user.component.scss']
})
export class ToolbarUserComponent implements OnInit {
  public email:string;
  public role_id:number;
  public name :string;
  public firstname:string;
  public surname :string;
  public position:string;
  public urlImage:string;
  public img:any; 
  isOpen: boolean;

  constructor() {
    this.role_id = LoginService.getUser().id_role;
    this.email = LoginService.getUser().email;
    this.name = LoginService.getUser().name;
    this.firstname = LoginService.getUser().firstname;
    this.surname = LoginService.getUser().surname;
    this.img = AuthService.getUser().img;
    this.urlImage =  '../assets/img/avatars/'+ this.img;
   }

  ngOnInit() {
  }

  toggleDropdown() {
    this.isOpen = !this.isOpen;
  }

  onClickOutside() {
    this.isOpen = false;
  }

}
