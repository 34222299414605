import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanActivateChild {
  constructor(private router: Router) {}
  
  canActivate(route: ActivatedRouteSnapshot) {
    //console.log(route);
    if(sessionStorage.getItem('isLoggedIn')){
      if(route.data['id'] === 0){
        return true;
      }
      if(AuthService.checkPermissions(route.data['id'])){
        return true;
      } else{
        this.router.navigate(['/']);
        return;
      }
    }else{
      if(route.data['id'] === -1){
        return true;
      }
    }

    this.router.navigate(['/login']);
    return;
  }
  
  canActivateChild() {
    if (AuthService.isAdmin() || AuthService.isSuperAdmin()) {
        return true;
    }

    this.router.navigate(['/dashboard']);
    return false;
}
}
