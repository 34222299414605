import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { LIST_FADE_ANIMATION } from '../../../../@fury/shared/list.animation';
import { GeneralService } from 'src/app/shared/services/general.service';
import { AuthService } from 'src/app/shared/services/auth.service';
import { User } from 'src/app/pages/users/users.model';

@Component({
  selector: 'fury-toolbar-notifications',
  templateUrl: './toolbar-notifications.component.html',
  styleUrls: ['./toolbar-notifications.component.scss'],
  animations: [...LIST_FADE_ANIMATION],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ToolbarNotificationsComponent implements OnInit {

  notifications: any[];
  isOpen: boolean;
  user: User;
  isAdmin: boolean;
  totalUnread: number;
  constructor( private generalService: GeneralService
    ) {
      this.user = AuthService.getUser();
      this.isAdmin = AuthService.isAdmin();
      this.notifications = [];
      this.totalUnread = 0;


  }

  ngOnInit() {
    console.log('this.isAdmin');
    console.log(this.isAdmin);
    console.log('this.user.id');
    console.log(this.user.id);
    this.generalService.getNotifications(this.user.id).subscribe(result => {
      console.log(result);
      this.notifications = result.rows;
      let totalU = 0;
      this.notifications.map(function(x) {
        totalU += (x.read ? 0 : 1);
        x.name =  (x.type == 1 ? 'Notificación de ' : 'Alerta de ') + x.name; 
        x.icon = x.type == 1 ? 'notifications' : 'warning_amber';
        x.colorClass = x.type == 1 ? 'primary' : 'accent';
      });
      this.totalUnread = totalU;
    });
    // this.notifications = [
    //   {
    //     icon: 'notifications',
    //     name: 'This is a notification',
    //     time: 'few sec ago',
    //     read: false,
    //     colorClass: ''
    //   },
    //   {
    //     icon: 'shopping_basket',
    //     name: 'User bought your template',
    //     time: '23 min ago',
    //     read: false,
    //     colorClass: 'primary'
    //   },
    //   {
    //     icon: 'eject',
    //     name: 'Server Crashed',
    //     time: 'an hour ago',
    //     read: false,
    //     colorClass: 'accent'
    //   },
    //   {
    //     icon: 'cached',
    //     name: 'New user registered',
    //     time: '6 hours ago',
    //     read: true,
    //     colorClass: ''
    //   },
    //   {
    //     icon: 'code',
    //     name: 'John added you as friend',
    //     time: 'yesterday',
    //     read: true,
    //     colorClass: ''
    //   }
    // ];
  }

  markAsRead(notification) {
    if(!notification.read){
      notification.read = true;
      this.totalUnread--;
      let idsN = [];
      let idsA = [];
      if(notification.type == 1){
        idsN.push(notification.id);
      }else{
        idsA.push(notification.id);
      }
      this.callMarkAsRead(idsN,idsA);
    }
  }

  dismiss(notification, event) {
    event.stopPropagation();
    this.notifications.splice(this.notifications.indexOf(notification), 1);
  }

  toggleDropdown() {
    this.isOpen = !this.isOpen;
  }

  onClickOutside() {
    this.isOpen = false;
  }

  markAllAsRead() {
    let idsN = [];
    let idsA = [];
    this.notifications.forEach(notification => {
      notification.read = true;
      if(notification.type == 1){
        idsN.push(notification.id);
      }else{
        idsA.push(notification.id);
      }
    });
    this.totalUnread = 0;
    if(idsN.length > 0 || idsA.length > 0){
      this.callMarkAsRead(idsN, idsA);
    }
  }

  callMarkAsRead(idsN,idsA){
    this.generalService.markAsRead({ids_n: idsN, ids_a: idsA, is_admin: this.isAdmin ? 1 : 0, id_user : this.user.id}).subscribe(result => {
      //console.log(result);
    });
  }
}
