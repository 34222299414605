import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GeneralService {
  private readonly urlBase = '/api/';
  constructor(private httpClient: HttpClient) { }

  getMenu():Observable<any[]>{
    return this.httpClient.get<any[]>(this.urlBase + 'getMenu');
  }

  getNotifications(idUser): Observable<any>{
    return this.httpClient.get(this.urlBase + 'getNotifications?id_user=' + idUser);
  }

  markAsRead(data): Observable<any>{
    return this.httpClient.post(this.urlBase + 'markAsRead', data)
  }
}
