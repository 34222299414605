import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LayoutComponent } from './layout/layout.component';
import { AuthGuard } from './shared/guard/guard.guard';

const routes: Routes = [
  {
    path: 'login',
    loadChildren: () => import('./pages/authentication/login/login.module').then(m => m.LoginModule),
  },
  {
    path: 'register',
    loadChildren: () => import('./pages/authentication/register/register.module').then(m => m.RegisterModule),
  },
  {
    path: 'forgot-password',
    loadChildren: () => import('./pages/authentication/forgot-password/forgot-password.module').then(m => m.ForgotPasswordModule),
  },
  {
    path: '', 
    component: LayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('./pages/dashboard/dashboard.module').then(m => m.DashboardModule),
        pathMatch: 'full',
        canActivate: [AuthGuard], data: {id: 1},
      },
      /*{
        path: 'tables/all-in-one-table',
        loadChildren: () => import('./pages/tables/all-in-one-table/all-in-one-table.module').then(m => m.AllInOneTableModule),
      },*/
      {
        path: 'catalogs',
        loadChildren: () => import('./pages/catalogs/catalogs.module').then(m => m.CatalogsModule),
        canActivate: [AuthGuard], data: {id: 2},
      },
      {
        path: 'opportunities',
        loadChildren: () => import('./pages/opportunities/opportunities.module').then(m => m.OpportunitiesModule),
        canActivate: [AuthGuard], data: {id: 3},
      },
      {
        path: 'users',
        loadChildren: () => import('../app/pages/users/users.module').then(m => m.usersModule),
        canActivate: [AuthGuard], data: {id: 4},
      },
      {
        path: 'myActivities',
        loadChildren: () => import('../app/pages/myActivities/myActivities.module').then(m => m.myActivitiesModule),
        canActivate: [AuthGuard], data: {id: 5},
      },
       {
        path: 'calendar',
        loadChildren: () => import('./pages/apps/calendar/calendar.module').then(m => m.CalendarAppModule),
        canActivate: [AuthGuard], data: {id: 6},
      },
      {
        path: 'course-programming',
        loadChildren: () => import('./pages/course-programming/course-programming.module').then(m => m.courseProgrammingModule),
        canActivate: [AuthGuard], data: {id: 7},
      },
      {
        path: 'student-assignment',
        loadChildren: () => import('./pages/student-assignment/student-assignment.module').then(m => m.studentAssignmentModule),
        canActivate: [AuthGuard], data: {id: 6},
      },     
      {
        path: 'summon',
        loadChildren: () => import('./pages/summon/summon.module').then(m => m.summonModule),
        canActivate: [AuthGuard], data: {id: 10},
      },
      {
        path: 'clients',
        loadChildren: () => import('./pages/Clients/Clients.module').then(m => m.ClientsModule),
        canActivate: [AuthGuard], data: {id:11},
      },
      {
        path: 'prospects',
        loadChildren: () => import('./pages/prospects/prospects.module').then(m => m.ProspectsModule),
        canActivate: [AuthGuard], data: {id:12},
      },
      {
        path: 'settings',
        loadChildren: () => import('./pages/settings/settings.module').then(m => m.SettingsModule),
        canActivate: [AuthGuard], data: {id: 13},
      }
      //{
      //   path: 'students-by-courses',
      //   loadChildren: () => import('./pages/students-by-courses/students-by-courses.module').then(m => m.StudentByCoursesModule),
      //   canActivate: [AuthGuard], data: {id: 14},
      // },
      // {
      //   path: 'inscriptions',
      //   loadChildren: () => import('./pages/inscriptions/inscriptions.module').then(m => m.InscriptionsModules),
      //   canActivate: [AuthGuard], data: {id: 15},
      // }     
      // {
      //   path: 'test',
      //   loadChildren: () => import('./pages/test/test.module').then(m => m.testModule),
      //   canActivate: [AuthGuard], data: {id: 11},
      // }
      /*
      /*{
        path: 'customers',
        loadChildren: () => import('./pages/customers/customers.module').then(m => m.CustomersModule),
      }*/
     
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabled',
    // preloadingStrategy: PreloadAllModules,
    scrollPositionRestoration: 'enabled',
    anchorScrolling: 'enabled'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
