import {Injectable} from '@angular/core';
import {BehaviorSubject,  Observable } from 'rxjs';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {User} from '../login/login.model';

@Injectable()
export class LoginService{

  private readonly urlBase = '/api/';

  constructor (private httpClient: HttpClient) {}

  login(data: any): Observable<any>{
    return this.httpClient.post(this.urlBase + 'login', data);
  }

  dataService(username:any): Observable<any>{
    return this.httpClient.post(this.urlBase + 'dataService?username='+ username ,username);
  }

  public static getUser(): User{
  return JSON.parse(sessionStorage.getItem('user'));
  }

  
}