<div (furyClickOutside)="onClickOutside()" class="toolbar-notifications"
     fxFlex="grow" fxLayout="row" fxLayoutAlign="start center">
  <button (click)="toggleDropdown()" [class.open]="isOpen" class="button"
          fxFlex="grow" mat-button type="button">
    <mat-icon [matBadgeHidden]="totalUnread == 0" [matBadge]="totalUnread" class="icon">
      notifications_active
    </mat-icon>
  </button>

  <div [class.open]="isOpen" class="dropdown">
    <fury-card>
      <fury-card-header class="dropdown-header">
        <fury-card-header-heading>
          Notifications
        </fury-card-header-heading>
        <fury-card-header-subheading>
          Tienes {{ totalUnread }} notificaciones nuevas.
        </fury-card-header-subheading>

        <fury-card-header-actions>
          <button mat-icon-button type="button">
            <mat-icon class="icon">settings</mat-icon>
          </button>
        </fury-card-header-actions>
      </fury-card-header>

      <fury-card-content class="dropdown-card-content">
        <fury-scrollbar class="dropdown-content">
          <div fxLayout="column">
            <ng-container *ngFor="let notification of notifications; let last = last">
              <div (click)="markAsRead(notification)"
                   [@listFade]
                   [class.read]="notification.read" [ngClass]="notification.colorClass"
                   class="notification" fxLayout="row"
                   fxLayoutAlign="start center" matRipple>
                <mat-icon class="icon">{{ notification.icon }}</mat-icon>
                <div class="label" fxLayout="column">
                  <div class="name">{{ notification.name }}</div>
                  <div class="time">{{ notification.time }}</div>
                </div>
                <span fxFlex></span>
                <button (click)="dismiss(notification, $event)" mat-icon-button type="button">
                  <mat-icon class="close">close</mat-icon>
                </button>
              </div>
              <mat-divider *ngIf="!last"></mat-divider>
            </ng-container>
          </div>
          <div *ngIf="totalUnread === 0" [@listFade] class="empty">
            Al día! No tienes notificaciónes ni alertas.
          </div>
        </fury-scrollbar>
      </fury-card-content>


      <fury-card-actions (click)="markAllAsRead()" class="dropdown-footer" matRipple>
        Marcar todo como leido
      </fury-card-actions>
    </fury-card>
  </div>
</div>
